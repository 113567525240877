@import url('https://fonts.googleapis.com/css?family=Lato:400,700');
$font: 'Lato', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
$primaryFont: 'Quicksand', sans-serif;

$primaryColor: #1A2F44;
$secondaryColor: #D91E1E;
$backGroundColor: #23374D;
$middleColor: #6D6864;
$lightestColor: #DAD8D5;


.navbar-container{
    // background-color: white;

    img{
        width: 250px;
        margin-right: 1%;
        border-radius: 1%;
        margin: 1%;
    }

    .me-auto{
        font-family: $font;
        font-weight: bold;
        font-size: 1.2rem;
        color: $primaryColor !important;
    }

    .brand-name{
        small{
            font-size: 0.8rem;
        }
    }
}