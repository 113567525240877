@import url('https://fonts.googleapis.com/css?family=Lato:400,700');
$font: 'Lato',
sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
$primaryFont: 'Quicksand',
sans-serif;

$primaryColor: #1A2F44;
$secondaryColor: #D91E1E;
$backGroundColor: #23374D;
$middleColor: #6D6864;
$lightestColor: #DAD8D5;

@media (max-width: 500px) {
    .total-container {

        .container-1 {
            .call-to-action-1 {
                width: 300px;
                margin-left: 2%;
                margin-right: 2%;
            }

            h1 {
                font-size: 3.8rem;
            }
        }

        .container-2 {
            .row-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                align-content: center;

                .gif-container {
                    width: 300px;
                    margin-bottom: 5%;

                }

                .container-list {
                    width: 300px;
                    p {
                        text-align: center;
                    }

                    h2{
                        font-size: 1rem;

                        img{
                            width: 30px;
                        }
                    }
                }
            }
        }

        .container-3{
            .fireMeme{
                width: 300px;
                height: 300px;
            }
            h3{
                font-size: 1rem;
                width: 300px;
            }
        }

        .container-5{
            width: 300px;
            height: 250px;
            .phone-img{
                display: none;
            }

            .text-5{
                display: flex;
                flex-direction: column;
                align-items: center;
                align-content: center;
                width: 250px;
                margin: 2%;

                h2{
                    font-size: 1.7rem;
                    text-align: center;
                }
            }
        }
    }

}