@import url('https://fonts.googleapis.com/css?family=Lato:400,700');
$font: 'Lato',
sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
$primaryFont: 'Quicksand',
sans-serif;

$primaryColor: #1A2F44;
$secondaryColor: #D91E1E;
$backGroundColor: #23374D;
$middleColor: #6D6864;
$lightestColor: #DAD8D5;

.total-container {
    background-color: $primaryColor;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;

    .container-3 {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        margin-top: 3%;

        h3 {
            color: $lightestColor;
            font-family: $primaryFont;
            font-weight: bold;
            margin-top: 1%;
            margin-bottom: 3%;
            font-size: 2rem;
        }

        img {
            height: 60vh;
        }

        .button-info {
            background-color: $secondaryColor;
            border-color: $secondaryColor;
            margin: 3%;
            font-family: $font;
            font-weight: bold;
            font-size: 1.5rem;
        }

        .sub-container-3 {
            display: flex;
            flex-direction: column;
            background-color: $backGroundColor;
            box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
            border-radius: 2%;

            .highlight {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                padding: 2%;
                align-items: center;
                align-content: center;
                justify-content: center;

                div {
                    display: flex;
                    flex-direction: row;
                    color: $lightestColor;
                    width: 90%;


                    justify-content: center;
                    align-items: center;
                    align-content: center;

                    h5 {
                        font-family: $primaryFont;
                        font-size: 1.8 rem;
                        margin-left: 2%;
                    }

                    img {
                        width: 50px;
                        height: inherit;
                    }
                }

                p {
                    color: $lightestColor;
                    font-family: $font;
                    margin-top: 1%;
                    width: 80%;
                }
            }
        }
    }

    .container-4 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;

        .call-to-action-4{
            background-color: $secondaryColor;
            border-color: $secondaryColor;
            margin: 3%;
            width: 40%;
        }

        h2 {
            font-family: $font;
            color: $lightestColor;
            font-size: 3rem;
            padding-bottom: 3%;
        }

        .scrolling-op{
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            justify-content: center;
            height: 60vh;
            overflow-y: scroll;
            img{
                width: 100%;
                margin: 2px;
            }
        }
    }

    .container-5{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        height: 50vh;
        // width: 80vh;
        width: 60%;
        border-radius: 5%;
        border-color: $lightestColor;
        border-width: 3px;
        border-style: solid;
        margin-bottom: 5%;
        background-color: $lightestColor;
    

        .text-5{
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            justify-content: center;

            div{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .button-5{
                    background-color: $primaryColor;
                    border-color: $primaryColor;
                    margin: 1%;
                    width: 100px;
                    height: 100px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                    border-radius: 5%;
                    margin-left: 2%;
    
    
                    img{
                        width: 100px;
                    }
                }
            }

            h2{
                font-family: $primaryFont;
                color: $primaryColor;
                font-size: 2.6rem;
                font-weight: bold;
                text-align: left;
                margin-left: 6%;
                padding-left: 3%;

            }
        }

        .phone-img{
            position: absolute;
            right: 2%;
            bottom: -15%;
            // margin-bottom: -5;
            width: 400px;
            // height: 40%;
        }
    }
}

.container-1 {
    background-color: $primaryColor;
    border-top: 0.5px solid $lightestColor;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
    justify-content: space-around;
    height: 90vh;

    .call-to-action-1 {
        // width: 80%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        background-color: $secondaryColor;
        justify-content: space-around;
        margin-top: 3%;
        border-radius: 10px;
        padding: 10px;
        align-items: center;
        align-content: center;
        // cursor: pointer;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
        ;

        h4 {
            font-family: $font;
            color: $lightestColor;
            font-weight: 400;
            padding-left: 10px;
            padding-right: 20px;
        }

        .button-1 {
            background-color: $backGroundColor;
            border-color: $backGroundColor;
        }
    }

    h1 {
        font-family: $primaryFont;
        color: $lightestColor;
        font-size: 5rem;
        padding-top: 4%;
        font-weight: bold;
    }

    h3 {
        font-family: $font;
        color: $lightestColor;
        font-size: 3rem;
        padding-bottom: 3%;
    }

    .call-to-action-2 {
        background-color: $secondaryColor;
        border-color: $secondaryColor;
        font-family: $primaryFont;
        font-weight: bold;
        margin-bottom: 3%;
    }
}

.container-2 {

    background: rgb(129, 129, 129);
    background: linear-gradient(90deg, rgba(129, 129, 129, 1) 0%, rgba(218, 216, 213, 1) 51%, rgba(235, 235, 235, 1) 100%);
    width: 90%;
    border-radius: 1%;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

    p {
        padding-top: 2%;
        font-family: $primaryFont;
        color: $primaryColor;
        font-size: 2rem;
    }


    .row-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .gif-container {
            margin-left: 0;
            align-self: flex-start;
            width: 50%;

            img {
                width: 100%;
                border-radius: 1%;
                box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
            }
        }

        .container-list {
            margin-right: 3%;
            width: 45%;

            p {
                font-family: $font;
                font-size: 1rem;
                text-align: left;
            }

            ul {
                text-align: left;
                font-family: $font;
                font-weight: bold;

                li {
                    color: $primaryColor
                }
            }

            h2 {
                color: $primaryColor;
                font-family: $primaryFont;
                font-weight: bold;
                margin-top: 5%;

                img {
                    width: 60px;
                }
            }



        }

    }

    .container-3 {
        background-color: white;
    }

    .container-button {
        .call-to-action-3 {
            margin-bottom: 2%;
        }
    }
}